.App {
  text-align: center;
  background-color: blue; /* Fundo azul */
  height: 100vh; /* Altura total da janela */
  color: white;
}

.App-header {
  background-color: black; /* Barra preta */
  display: flex;
  align-items: center;
  padding: 10px 20px;
}

.logo {
  margin-right: auto; /* Alinha o logo à esquerda */
  font-size: 24px; /* Tamanho do símbolo */
}

.navbar {
  display: flex;
  justify-content: center; /* Centraliza os botões */
  flex-grow: 1; /* Permite que a barra de navegação ocupe o espaço restante */
}

.nav-button {
  background-color: transparent; /* Fundo transparente */
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
}

.nav-button:hover {
  text-decoration: underline; /* Efeito de hover */
}

.main-content {
  padding: 20px;
}
